export default class MainPageModel {
    constructor() {
        this.iin = "";
        this.iinError = "";
        this.alserOrderNumber = "";
        this.alserOrderNumberError = "";
        this.orderNumberParthner = "";
        this.orderNumberParthnerError = "";
        this.orderId = "";
        this.code = "";
        this.isShowModal = false;
        this.isErrorMessageModal = false;
    }
}
